// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-js": () => import("./../src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-en-js": () => import("./../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-fr-js": () => import("./../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-it-js": () => import("./../src/pages/it.js" /* webpackChunkName: "component---src-pages-it-js" */)
}

